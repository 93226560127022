/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './style.module.scss';

const Address = ({
  title,
  address,
  isClickAndCollect,
  additionalText,
  addressType,
}) => {
  const isCollectionAddress = (isClickAndCollect);

  let className = styles.address;
  if (addressType === 'billing') {
    className += ` ${styles.billingAddress}`;
  }

  return (
    <div className={className}>
      {title && (<h4>{title}</h4>)}
      {additionalText && (<p>{additionalText}</p>)}
      <address>
        {(address.firstname && !isCollectionAddress) && (
          <span>
            {address.firstname}&nbsp;{address.lastname}<br />
          </span>
        )}
        {address.company && (<span>{address.company},<br /></span>)}
        {address.street[0] && (<span>{address.street[0]},{'\u00A0'}</span>)}
        {address.street[1] && (<span>{address.street[1]},{'\u00A0'}</span>)}
        {address.city && (<span>{address.city},<br /></span>)}
        {address.region && (<span>{address.region},{'\u00A0'}</span>)}
        {/*{address.country_id && (<span>{address.country_id},<br /></span>)}*/}
        {address.postcode && (<span>{address.postcode}<br /></span>)}
        {address.telephone && (<span>{address.telephone}</span>)}

        {/*{(address.firstname && isCollectionAddress) && (*/}
        {/*  <>*/}
        {/*    <h4>Collector&apos;s Name</h4>*/}
        {/*    <span>*/}
        {/*      {address.firstname}&nbsp;{address.lastname}*/}
        {/*    </span>*/}
        {/*  </>*/}
        {/*)}*/}
      </address>
    </div>
  );
};

Address.propTypes = {
  title: PropTypes.string,
  address: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    company: PropTypes.string,
    street: PropTypes.arrayOf(PropTypes.string),
    city: PropTypes.string,
    postcode: PropTypes.string,
    country_id: PropTypes.string,
    telephone: PropTypes.string,
    region: PropTypes.string,
  }),
  isClickAndCollect: PropTypes.bool,
  additionalText: PropTypes.string,
  addressType: PropTypes.string,
};

Address.defaultProps = {
  title: '',
  address: {
    firstname: '',
    lastname: '',
    company: '',
    street: ['', ''],
    city: '',
    postcode: '',
    country_id: '',
    telephone: '',
    region: '',
  },
  isClickAndCollect: false,
  additionalText: '',
  addressType: '',
};

export default Address;
